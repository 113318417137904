<i18n>
{
  "ru": {
    "title": "Добавление заказа",
    "total": "Итого",
    "receipts": "Прием",
    "delivery": "Доставка",
    "allSum": "Общая сумма",
    "paid": "Оплачено",
    "diff": "Осталось"
  },
  "he": {
    "title": "הוספת הזמנה",
    "total": "סה''כ",
    "receipts": "קבלה",
    "delivery": "משלוח",
    "allSum": "סך הכל",
    "paid": "בתשלום",
    "diff": "נשאר"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsInfo">
          <v-col
            :cols="item.col || 3"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
                clearable
              ></v-text-field>
            </template>

            <template v-if="item.type === 'select'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
                clearable
              ></v-select>
            </template>

            <template v-if="item.type === 'payments'">
              <v-select
                v-model="item.value"
                :items="paymentsClient"
                item-text="name"
                item-value="id"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
                clearable
              ></v-select>
            </template>

            <template v-if="item.type === 'paid'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                color="teal"
                clearable
                @change="setPaidSum($event)"
              ></v-select>
            </template>

            <template v-if="item.type === 'paidSum'">
              <v-text-field
                v-model.number="item.value"
                :label="item.name"
                :disabled="disabledPaidSum"
                :required="item.required"
                :rules="item.rules"
                color="teal"
                clearable
              ></v-text-field>
            </template>

            <template v-else-if="item.type === 'delivery'">
              <SelectDelivery
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                @inputObject="setDeliveryPrice($event)"
              ></SelectDelivery>
            </template>

            <template v-else-if="item.type === 'receipts'">
              <SelectReceipts
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                @inputObject="setReceiptPrice($event)"
              ></SelectReceipts>
            </template>

            <template v-else-if="item.type === 'client'">
              <AutocompleteClient
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                :hint="(clientSelect) ? clientSelect.ADDRESS : ''"
                @change="resetPaymentProp()"
                @inputObject="clientSelect = $event"
              ></AutocompleteClient>
            </template>
          </v-col>
        </template>
      </v-row>

      <v-expansion-panels
        multiple
        class="mb-5"
      >
        <template v-for="(group, index) in goodsTree">
          <v-expansion-panel
            :key="`goods-grops-${index}`"
          >
          <v-expansion-panel-header
            :color="(group.goodsSelected.length > 0) ? 'teal lighten-3' : undefined"
          >{{ group.UF_NAME }} ({{group.sum}})</v-expansion-panel-header>

            <v-expansion-panel-content>
              <AutocompleteGood
                :label="$t('goods')"
                :group-id="group.ID"
                @inputObject="addGood($event)"
                class="mb-3"
              ></AutocompleteGood>

              <template v-if="group.goodsSelected.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="group.goodsSelected"
                  :items-per-page="-1"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:item.UF_QUANTITY="{ item }">
                    <div class="d-inline-flex align-center">
                      <v-btn
                        color="teal"
                        icon
                        @click="setQuantity(item.UF_QUANTITY - item.UF_RATIO, item)"
                      >
                        <v-icon>
                          mdi-minus
                        </v-icon>
                      </v-btn>

                      <v-text-field
                        :value="item.UF_QUANTITY"
                        color="teal"
                        @input="setQuantity($event, item)"
                      ></v-text-field>

                      <v-btn
                        color="teal"
                        icon
                        @click="setQuantity(item.UF_QUANTITY + item.UF_RATIO, item)"
                      >
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>

                  <template v-slot:item.UF_PRICE="{ item }">
                    <v-text-field
                      :value="item.UF_PRICE"
                      color="teal"
                      @input="setPrice($event, item)"
                      :disabled="!item.changePrice"
                    ></v-text-field>
                  </template>

                  <template v-slot:item.changePrice="{ item }">
                    <v-switch
                      v-model="item.changePrice"
                      color="teal"
                    ></v-switch>
                  </template>

                  <template v-slot:item.btn="{ item }">
                    <v-tooltip
                      bottom
                      color="grey darken-3"
                      :open-on-click="false"
                      :open-on-focus="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="teal"
                          dark
                          fab
                          class="ma-2"
                          v-bind="attrs"
                          v-on="on"
                          @click="goodDelete(item)"
                        >
                          <v-icon dark>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>

                      <span>{{ $t('btn.delete') }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <div class="text-right text-h6 mt-3">
                  {{ $t('total') }}: {{ group.sum }}
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>

      <div class="text-right text-h5 mt-3">
        {{ $t('total') }}: {{ allSum }}
      </div>

      <v-row>
        <template v-for="(item, index) in propsDopInfo">
          <v-col
            cols="6"
            :key="`prop-item-dop-info-${index}`"
          >
            <template v-if="item.type === 'textarea'">
              <v-textarea
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                clearable
                color="teal"
              ></v-textarea>
            </template>

            <template v-if="item.type === 'file'">
              <v-file-input
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
                :accept="item.accept"
                :multiple="item.multiple"
                color="teal"
                clearable
                :prepend-icon="item.icon"
                @change="getImages(item.value)"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    small
                    label
                    dark
                    :close="item.multiple"
                    color="teal"
                    @click:close="item.value.splice(index, 1); getImages(item.value)"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>

              <template v-if="imagesList.length > 0">
                <v-row>
                  <template v-for="(img, i) in imagesList">
                    <v-col
                      :key="`input-img-${i}`"
                      cols="4"
                    >
                      <v-img
                        :src="img"
                        aspect-ratio="1.7"
                      ></v-img>
                    </v-col>
                  </template>
                </v-row>
              </template>
            </template>
          </v-col>
        </template>
      </v-row>

      <div class="text-right text-h5 mt-3">
        <p>
          {{ $t('receipts') }}: {{ receiptPrice }}
          <br>{{ $t('delivery') }}: {{ deliveryPrice }}
          <br>{{ $t('allSum') }}: {{ orderSum }}
        </p>

        <p>
          {{ $t('paid') }}: {{ paidSumComp }}
          <br>{{ $t('diff') }}: {{ paidSumStayComp }}
        </p>
      </div>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="save()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/orders/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import AutocompleteClient from '@/components/AutocompleteClient.vue';
import AutocompleteGood from '@/components/AutocompleteGood.vue';
import SelectDelivery from '@/components/SelectDelivery.vue';
import SelectReceipts from '@/components/SelectReceipts.vue';

export default {
  name: 'OrderAdd',
  components: {
    AutocompleteClient,
    AutocompleteGood,
    SelectDelivery,
    SelectReceipts,
  },
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {
      show: false,
      color: 'green darken-3',
      text: 'Заказ сохранен',
    },
    clientSelect: {},
    imagesList: [],
    deliveryPrice: 0,
    receiptPrice: 0,
    props: [
      {
        name: vm.$t('props.UF_CLIENT'),
        code: 'UF_CLIENT',
        type: 'client',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: null,
        list: [],
        group: 'info',
      },
      {
        name: vm.$t('props.UF_PAYMENT'),
        code: 'UF_PAYMENT',
        type: 'payments',
        disabled: false,
        required: false,
        // rules: [
        //   (v) => !!v || vm.$t('required'),
        // ],
        value: null,
        list: [],
        group: 'info',
      },
      {
        name: vm.$t('props.UF_RECEIPTS'),
        code: 'UF_RECEIPTS',
        type: 'receipts',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: null,
        group: 'info',
      },
      {
        name: vm.$t('props.UF_DELIVERY'),
        code: 'UF_DELIVERY',
        type: 'delivery',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: null,
        group: 'info',
      },
      {
        name: vm.$t('props.UF_PAID'),
        code: 'UF_PAID',
        type: 'paid',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: 3,
        list: [
          {
            id: 3,
            name: vm.$t('status.paid.no'),
          },
          {
            id: 4,
            name: vm.$t('status.paid.partly'),
          },
          {
            id: 5,
            name: vm.$t('status.paid.yes'),
          },
          {
            id: 6,
            name: vm.$t('status.paid.noPaid'),
          },
        ],
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_PAID_SUM'),
        code: 'UF_PAID_SUM',
        type: 'paidSum',
        disabled: true,
        required: true,
        rules: [
          (v) => (!!v || v === 0) || vm.$t('required'),
        ],
        value: 0,
        group: 'info',
        col: '6',
      },
      {
        name: vm.$t('props.UF_PHOTO'),
        code: 'UF_PHOTO',
        type: 'file',
        disabled: false,
        required: false,
        accept: 'image/png, image/jpeg, image/bmp',
        multiple: true,
        rules: [],
        icon: 'mdi-camera',
        value: null,
        group: 'dopInfo',
      },
      {
        name: vm.$t('props.UF_COMMENT'),
        code: 'UF_COMMENT',
        type: 'textarea',
        disabled: false,
        required: false,
        rules: [],
        value: null,
        group: 'dopInfo',
      },
      {
        name: vm.$t('goods'),
        code: 'goods',
        group: 'goods',
        value: [],
      },
    ],
    headers: [
      {
        text: vm.$t('props.UF_NAME'),
        value: 'UF_NAME',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_QUANTITY'),
        value: 'UF_QUANTITY',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_PRICE'),
        value: 'UF_PRICE',
        sortable: false,
      },
      {
        text: vm.$t('changePrice'),
        value: 'changePrice',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
  }),
  watch: {
    clientRoutParam() {
      this.setClient();
    },
  },
  computed: {
    goodsGroups() {
      return this.$store.getters.getGroupsGoods;
    },
    payments() {
      return this.$store.getters.getPayments;
    },
    disabledPaidSum() {
      const findProp = this.props.find((prop) => prop.code === 'UF_PAID');

      return (findProp && findProp.value !== 4);
    },
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
    propsDopInfo() {
      return this.props.filter((item) => item.group === 'dopInfo');
    },
    goodsTree() {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        const groups = this.goodsGroups.map((group) => {
          const groupMod = group;

          groupMod.goodsSelected = findProp.value.filter((good) => good.UF_GROUP === groupMod.ID);

          groupMod.sum = groupMod.goodsSelected.reduce((sum, good) => {
            const total = sum + +(good.UF_QUANTITY * good.UF_PRICE).toFixed(2);
            return total;
          }, 0);

          return groupMod;
        });

        return groups;
      }

      return [];
    },
    allSum() {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        return findProp.value.reduce((sum, good) => {
          const total = sum + +(good.UF_QUANTITY * good.UF_PRICE).toFixed(2);
          return total;
        }, 0);
      }

      return 0;
    },
    paymentsClient() {
      if (this.clientSelect) {
        const payments = [...this.payments];

        if (this.clientSelect.UF_DEPOSIT !== '1') {
          const index = payments.findIndex((item) => item.id === 2);

          payments.splice(index, 1);
        }

        return payments;
      }

      return [];
    },
    clientRoutParam() {
      return +this.$route.params.client;
    },
    orderSum() {
      return this.deliveryPrice + this.receiptPrice + this.allSum;
    },
    paidSumComp() {
      const findProp = this.props.find((prop) => prop.code === 'UF_PAID_SUM');

      return (findProp) ? findProp.value : 0;
    },
    paidSumStayComp() {
      return this.orderSum - this.paidSumComp;
    },
  },
  methods: {
    resetPaymentProp() {
      const findProp = this.props.find((prop) => prop.code === 'UF_PAYMENT');

      if (findProp) {
        findProp.value = null;
      }
    },
    setPaidSum(val) {
      const findProp = this.props.find((prop) => prop.code === 'UF_PAID_SUM');

      if (findProp) {
        switch (val) {
          case 5:
            findProp.value = this.orderSum;
            break;

          case 3:
          case 4:
          case 6:
          default:
            findProp.value = 0;
        }
      }
    },
    setDeliveryPrice(val) {
      this.deliveryPrice = (val) ? val.UF_PRICE : 0;
    },
    setReceiptPrice(val) {
      this.receiptPrice = (val) ? val.UF_PRICE : 0;
    },
    setClient() {
      const findProp = this.props.find((prop) => prop.code === 'UF_CLIENT');

      if (findProp) {
        findProp.value = (this.clientRoutParam) ? this.clientRoutParam : null;
      }
    },
    addGood(item) {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp && item) {
        const itemMod = { ...item };
        itemMod.UF_QUANTITY = 0;
        itemMod.UF_COMPLETED = '0';
        itemMod.changePrice = false;

        findProp.value.push(itemMod);
      }
    },
    setQuantity(quantity, item) {
      const goodChange = { ...item };

      if (+quantity && +quantity >= 0) {
        goodChange.UF_QUANTITY = Math.round(+quantity * 100) / 100;
      } else {
        goodChange.UF_QUANTITY = 0;
      }

      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        const findIndexGood = findProp.value.findIndex((good) => good.ID === goodChange.ID);

        if (findIndexGood !== -1) {
          findProp.value.splice(findIndexGood, 1, goodChange);
        }
      }
    },
    setPrice(price, item) {
      const goodChange = { ...item };

      if (+price) {
        goodChange.UF_PRICE = Math.round(+price * 100) / 100;
      }

      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        const findIndexGood = findProp.value.findIndex((good) => good.ID === goodChange.ID);

        if (findIndexGood !== -1) {
          findProp.value.splice(findIndexGood, 1, goodChange);
        }
      }
    },
    goodDelete(item) {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        const findIndexGood = findProp.value.findIndex((good) => good.ID === item.ID);

        if (findIndexGood !== -1) {
          findProp.value.splice(findIndexGood, 1);
        }
      }
    },
    getImages(value) {
      this.imagesList = [];

      if (Array.isArray(value)) {
        value.forEach((item) => {
          this.getImage(item);
        });
      } else if (value) {
        this.getImage(value);
      }
    },
    getImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imagesList.push(e.target.result);
      };

      reader.readAsDataURL(file);
    },
    save() {
      if (this.valid) {
        this.btnLoading = true;
        const params = new FormData();
        const props = {};

        this.props.forEach((prop) => {
          if (prop.code === 'UF_PHOTO') return;

          props[prop.code] = prop.value;
        });

        params.append('params', JSON.stringify(props));

        const fileProps = this.props.filter((prop) => prop.type === 'file');

        if (fileProps.length > 0) {
          fileProps.forEach((prop) => {
            if (prop.value) {
              prop.value.forEach((val, index) => {
                params.append(`${prop.code}[${index}]`, val);
              });
            }
          });
        }

        this.$store.dispatch('addOrder', params).then((response) => {
          const { success } = response.data.data;

          this.btnLoading = false;
          this.snackbar = {
            show: true,
            color: (success) ? 'green darken-3' : 'red darken-3',
            text: response.data.data.message,
          };

          if (success) {
            setTimeout(() => {
              this.$router.push('/orders/status/1/');
            }, 1500);
          }
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
  created() {
    this.setClient();
  },
};
</script>

<style lang="scss">

</style>
